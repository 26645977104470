// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import ExperienceView from '@/views/ExperienceView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/experience',
    name: 'Experience',
    component: ExperienceView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
