import { ref, onMounted, onUnmounted } from 'vue';

export const useTitleRotate = (titles = {}, rotateInterval = 3) => {
  // Create a reactive variable for the current title
  const currentTitle = ref({ key: '', value: '' });
  
  // Function to update the current title every 3 seconds
  let intervalId = null;

  onMounted(() => {
    const keys = Object.keys(titles);
    let index = 0;

    // Set the initial title
    currentTitle.value = { key: keys[index], value: titles[keys[index]] };

    // Start interval to rotate titles every 3 seconds
    intervalId = setInterval(() => {
      index = (index + 1) % keys.length; // Loop through the keys
      currentTitle.value = { key: keys[index], value: titles[keys[index]] };
    }, rotateInterval); 
  });

  onUnmounted(() => {
    // Clear the interval when the component is unmounted
    if (intervalId) clearInterval(intervalId);
  });

  return {
    currentTitle, // Return the reactive title
  };
};
