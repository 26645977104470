<script setup>
import { useTitleRotate } from '@/composables/useTitleRotate'

const titles = {
  engineer: "software engineer",
  devOps: "dev ops",
  game: "game developer",
}

const { currentTitle } = useTitleRotate(titles, 500);

</script>

<template>

    <div data-grid-container class="grid grid-cols-12 grid-rows-10 lg:grid-rows-12 w-full gap-3 p-3  bg-[#283D3B] h-screen overflow-auto font-mono">
      <div data-grid-item-1 class="col-start-2 col-end-12 md:col-start-4 md:col-end-10 lg:col-start-2 lg:col-end-7 row-start-1 4xl:row-start-2 mt-5">
          <div data-grid-item-1-container class="flex flex-col border text-center p-3 text-[#EDDDD4] bg-[#772E25] lg:w-[80%] 2xl:w-[50%] mx-auto lg:text-[1.5em]">
            <h2 class="text-[1.25em] font-bold">boskyle <span class="font-bold">/</span> Bos</h2>
            <small class="text-[0.75em] italic" v-html="currentTitle.value"></small>
          </div>
      </div>
      <div data-grid-item-2 class="col-start-2 col-end-12 md:col-start-4 md:col-end-10 lg:col-start-2 lg:col-end-7 row-start-3  4xl:row-start-4">
        <div data-grid-item-2-container class="flex flex-col  p-0 text-[#EDDDD4] gap-3">
          <h2 class="text-[1.75em] lg:text-[2em] font-bold">intro</h2>
          <p class="text-[0.7em] sm:text-[1em] 2xl:text-[1.2em] lg:text-left leading-[1.5em] md:max-h-max overflow-y-auto">
          I’m a <span class="bg-[#f4e1d2] font-semibold text-gray-700">software engineer</span> with two years of experience, focused on building <span class="bg-[#f4e1d2] font-semibold text-gray-700">scalable</span>, <span class="bg-[#f4e1d2] font-semibold text-gray-700">reliable systems</span> and writing <span class="bg-[#f4e1d2] font-semibold text-gray-700">clean</span>, <span class="bg-[#f4e1d2] font-semibold text-gray-700">maintainable code</span>. I design solutions that solve today’s problems but are also built to <span class="bg-[#f4e1d2] font-semibold text-gray-700">grow</span>, keeping things <span class="bg-[#f4e1d2] font-semibold text-gray-700">robust</span> as demands increase.
          <br><br>
          I run my own <span class="bg-[#f4e1d2] font-semibold text-gray-700">home server</span>, using my <span class="bg-[#f4e1d2] font-semibold text-gray-700">Linux expertise</span> to streamline everything from <span class="bg-[#f4e1d2] font-semibold text-gray-700">Dockerization</span> to <span class="bg-[#f4e1d2] font-semibold text-gray-700">reverse proxies</span>, making sure <span class="bg-[#f4e1d2] font-semibold text-gray-700">deployments</span> and <span class="bg-[#f4e1d2] font-semibold text-gray-700">system performance</span> are always on point. I also stick to <span class="bg-[#f4e1d2] font-semibold text-gray-700">best practices</span> with <span class="bg-[#f4e1d2] font-semibold text-gray-700">Git</span>, ensuring smooth <span class="bg-[#f4e1d2] font-semibold text-gray-700">version control</span> and <span class="bg-[#f4e1d2] font-semibold text-gray-700">collaboration</span>.
          <br><br>
          On top of that, I set up <span class="bg-[#f4e1d2] font-semibold text-gray-700">CI/CD pipelines</span> to streamline development. Just like this website—it’s running smoothly thanks to <span class="bg-[#f4e1d2] font-semibold text-gray-700">CI/CD</span> for continuous updates and improvements—I make sure systems are <span class="bg-[#f4e1d2] font-semibold text-gray-700">easy to deploy</span>, <span class="bg-[#f4e1d2] font-semibold text-gray-700">scalable</span>, and always running efficiently.
          </p>
        </div>
      </div>
      <div data-grid-item-3 class="col-start-2 col-end-12 md:col-start-4 md:col-end-10 lg:col-start-7 lg:col-end-13 row-start-10 lg:row-start-1 lg:row-end-13">
        <div data-grid-item-3-container class="flex flex-col text-center p-0 text-[#EDDDD4] gap-0 underline h-full justify-center lg:text-[2em]">
          <router-link to="/experience">
            <small class="hover:cursor-pointer">experience</small>
          </router-link>
          <small class="hover:cursor-pointer">projects</small>
          <small class="hover:cursor-pointer">self</small>
        </div>
      </div>
    </div>

</template>

